import axios from '@axios'

export default {
  namespaced: true,
  state: {

  },
  getters: {
  },
  mutations: {

  },
  actions: {
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/application/eCommerce/styleApplication/get')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/styleApplication/update', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
