<template>
  <div>
    <b-card :title="$t('Style')">

      <b-row>
        <b-col cols="12">
          <b-tabs
            content-class="mt-3"
            justified
          >
            <b-tab
              v-for="(item, index) in pages"
              :key="index"
              :title="item.tagMenu"
              :active="indexSelected === index"
              @click="selectPage(index)"
            >
              <b-table-simple>
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-td style="width: 20%;">
                      {{ $t('List') }}
                    </b-td>
                    <b-td style="width: 16%;">
                      {{ $t('Setting') }}
                    </b-td>
                    <b-td style="width: 16%;" />
                    <b-td style="width: 16%;" />
                    <b-td style="width: 16%;" />
                    <b-td style="width: 16%;" />
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-if="OptionRenderStyles.length>0">
                    <b-td>{{ $t('Render Style') }} : </b-td>
                    <b-td colspan="5">
                      <b-form-group
                        :label="$t('renderStyle')"
                        label-for="register-renderStyle"
                      >
                        <v-select
                          id="register-renderStyle"
                          v-model="item.renderStyle"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="OptionRenderStyles"
                          :reduce="(OptionRenderStyles) => OptionRenderStyles.value"
                          class="select-size-lg"
                          :clearable="false"
                        />
                      </b-form-group>
                    </b-td>

                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Background Image') }} : </b-td>
                    <b-td colspan="5">

                      <b-form-group
                        :label="$t('Image')"
                        label-for="register-image"
                      >
                        <!-- media -->
                        <b-media no-body>
                          <b-media-aside>
                            <b-link>
                              <b-img
                                v-if="item.backgroundImage"
                                ref="previewEl"
                                rounded
                                :src="item.backgroundImage"
                                height="80"
                              />
                              <b-img
                                v-else
                                ref="previewEl"
                                :src="require('@/assets/images/blank/no_image.png')"
                                rounded
                                height="80"
                              />
                            </b-link>
                            <!--/ avatar -->
                          </b-media-aside>

                          <b-media-body class="mt-75 ml-75">
                            <!-- upload button -->
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              size="sm"
                              class="mb-75 mr-75"
                              @click="addFileInput(index)"
                            >
                              {{ $t('Upload') }}
                            </b-button>
                            <b-form-file
                              :ref="`fileInput_${index}`"
                              accept=".webp, .jpeg, .jpg, .png, .gif"
                              :hidden="true"
                              plain
                              @change="inputImageRenderer($event, index)"
                            />
                            <!--/ upload button -->

                            <!-- reset -->
                            <b-button
                              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                              variant="outline-secondary"
                              size="sm"
                              class="mb-75 mr-75"
                              @click="resetImage(index)"
                            >
                              {{ $t('Reset') }}
                            </b-button>
                            <!--/ reset -->
                            <b-card-text>{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG.
                              {{ $t('Max size of') }} 300KB</b-card-text>
                            <b-card-text>
                              {{ $t('Supported resolutions') }} 640×480, 800×600, 960×720, 1024×768, 1280×960, 1400×1050, 1440×1080, 1600×1200, 1856×1392, 1920×1440,
                            </b-card-text>
                          </b-media-body>
                        </b-media>
                        <!--/ media -->
                      </b-form-group>
                    </b-td>

                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Used') }} : </b-td>
                    <b-td colspan="5">
                      <b-form-group
                        :label="$t('Used')"
                        :label-for="`input-lg-used-${index}`"
                      >
                        <b-form-checkbox
                          :id="`input-lg-used-${index}`"
                          v-model="item.used"
                          class="custom-control-primary"
                          name="check-button"
                          switch
                        />
                      </b-form-group>
                    </b-td>

                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Card Content') }} : </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Card Background Color')"
                        label-for="input-lg-cardBackgroundColor"
                      >
                        <b-form-input
                          id="input-lg-cardBackgroundColor"
                          v-model="item.styles.cardBackgroundColor"
                          placeholder="Card Background Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-cardBackgroundColor"
                          v-model="item.styles.cardBackgroundColor"
                          placeholder="Card Background Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Card Border Color')"
                        label-for="input-lg-cardBorderColor"
                      >
                        <b-form-input
                          id="input-lg-cardBorderColor"
                          v-model="item.styles.cardBorderColor"
                          placeholder="Card Border Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-cardBorderColor"
                          v-model="item.styles.cardBorderColor"
                          placeholder="Card Border Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Card Border Radius')"
                        label-for="input-lg-cardBorderRadius"
                      >
                        <!-- <b-form-input
                          id="input-lg-cardBorderRadius"
                          v-model="item.styles.cardBorderRadius"
                          placeholder="Card Border Radius"
                        /> -->
                        <cleave
                          id="input-lg-cardBorderRadius"
                          v-model="item.styles.cardBorderRadius"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="Card Border Radius"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Card Border Width')"
                        label-for="input-lg-cardBorderWidth"
                      >
                        <!-- <b-form-input
                          id="input-lg-cardBorderWidth"
                          v-model="item.styles.cardBorderWidth"
                          placeholder="Card Border Width"
                        /> -->
                        <cleave
                          id="input-lg-cardBorderWidth"
                          v-model="item.styles.cardBorderWidth"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="Card Border Width"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td />
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Title Background') }} : </b-td>
                    <b-td colspan="2">
                      <b-form-group
                        :label="$t('Background Title Current')"
                        label-for="input-lg-backgroundTitleCurrentColor"
                      >
                        <b-form-input
                          id="input-lg-backgroundTitleCurrentColor"
                          v-model="item.styles.backgroundTitleCurrentColor"
                          placeholder="Background Title Current Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-backgroundTitleCurrentColor"
                          v-model="item.styles.backgroundTitleCurrentColor"
                          placeholder="Background Title Current Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td colspan="2">
                      <b-form-group
                        :label="$t('Background Title New')"
                        label-for="input-lg-backgroundTitleNewColor"
                      >
                        <b-form-input
                          id="input-lg-backgroundTitleNewColor"
                          v-model="item.styles.backgroundTitleNewColor"
                          placeholder="Background Title New Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-backgroundTitleNewColor"
                          v-model="item.styles.backgroundTitleNewColor"
                          placeholder="Background Title New Color"
                        />
                      </b-form-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Title Icon') }} : </b-td>
                    <b-td colspan="2">
                      <b-form-group
                        :label="$t('Icon Title Current')"
                        label-for="input-lg-iconTitleCurrentColor"
                      >
                        <b-form-input
                          id="input-lg-iconTitleCurrentColor"
                          v-model="item.styles.iconTitleCurrentColor"
                          placeholder="Icon Title Curren Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-iconTitleCurrentColor"
                          v-model="item.styles.iconTitleCurrentColor"
                          placeholder="Icon Title Curren Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td colspan="2">
                      <b-form-group
                        :label="$t('Icon Title New')"
                        label-for="input-lg-iconTitleNewColor"
                      >
                        <b-form-input
                          id="input-lg-iconTitleNewColor"
                          v-model="item.styles.iconTitleNewColor"
                          placeholder="Icon Title New Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-iconTitleNewColor"
                          v-model="item.styles.iconTitleNewColor"
                          placeholder="Icon Title New Color"
                        />
                      </b-form-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Title Icon Action') }} : </b-td>
                    <b-td colspan="2">
                      <b-form-group
                        :label="$t('Icon Title Action Current')"
                        label-for="input-lg-iconTitleActionCurrentColor"
                      >
                        <b-form-input
                          id="input-lg-iconTitleActionCurrentColor"
                          v-model="item.styles.iconTitleActionCurrentColor"
                          placeholder="Icon Title Action Current Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-iconTitleActionCurrentColor"
                          v-model="item.styles.iconTitleActionCurrentColor"
                          placeholder="Icon Title Action Current Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td colspan="2">
                      <b-form-group
                        :label="$t('Icon Title Action New')"
                        label-for="input-lg-iconTitleActionNewColor"
                      >
                        <b-form-input
                          id="input-lg-iconTitleActionNewColor"
                          v-model="item.styles.iconTitleActionNewColor"
                          placeholder="Icon Title Action New Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-iconTitleActionNewColor"
                          v-model="item.styles.iconTitleActionNewColor"
                          placeholder="Icon Title Action New Color"
                        />
                      </b-form-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Title Text') }} : </b-td>
                    <b-td colspan="2">
                      <b-form-group
                        :label="$t('Text Title Current')"
                        label-for="input-lg-textTitleCurrentColor"
                      >
                        <b-form-input
                          id="input-lg-textTitleCurrentColor"
                          v-model="item.styles.textTitleCurrentColor"
                          placeholder="Text Title Current Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-textTitleCurrentColor"
                          v-model="item.styles.textTitleCurrentColor"
                          placeholder="Text Title Current Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td colspan="2">
                      <b-form-group
                        :label="$t('Text Title New')"
                        label-for="input-lg-textTitleCurrentColor"
                      >
                        <b-form-input
                          id="input-lg-textTitleNewColor"
                          v-model="item.styles.textTitleNewColor"
                          placeholder="Text Title New Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-textTitleNewColor"
                          v-model="item.styles.textTitleNewColor"
                          placeholder="Text Title New Color"
                        />
                      </b-form-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Padding Content') }} : </b-td>
                    <b-td colspan="5">
                      <b-form-group
                        :label="$t('Padding Content')"
                        label-for="input-lg-paddingContent"
                      >
                        <!-- <b-form-input
                          id="input-lg-paddingContent"
                          v-model="item.styles.paddingContent"
                          placeholder="Padding Content"
                        /> -->
                        <cleave
                          id="input-lg-paddingContent"
                          v-model="item.styles.paddingContent"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="Padding Content"
                        />
                      </b-form-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('SideBar Text') }} : </b-td>
                    <b-td colspan="2">
                      <b-form-group
                        :label="$t('SideBar Text Size')"
                        label-for="input-lg-sideBarTextSize"
                      >
                        <!-- <b-form-input
                          id="input-lg-iconTopicSize"
                          v-model="item.styles.iconTopicSize"
                          placeholder="Icon Topic Size"
                        /> -->
                        <cleave
                          id="input-lg-sideBarTextSize"
                          v-model="item.styles.sideBarTextSize"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="SideBar Text Size"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('SideBar Text Handle Color')"
                        label-for="input-lg-sideBarTextHandleColor"
                      >
                        <b-form-input
                          id="input-lg-sideBarTextHandleColor"
                          v-model="item.styles.sideBarTextHandleColor"
                          placeholder="SideBar Text Handle Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-sideBarTextHandleColor"
                          v-model="item.styles.sideBarTextHandleColor"
                          placeholder="SideBar Text Handle Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('SideBar Text Actived Color')"
                        label-for="input-lg-sideBarTextActivedColor"
                      >
                        <b-form-input
                          id="input-lg-sideBarTextActivedColor"
                          v-model="item.styles.sideBarTextActivedColor"
                          placeholder="SideBar Text Actived Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-sideBarTextActivedColor"
                          v-model="item.styles.sideBarTextActivedColor"
                          placeholder="SideBar Text Actived Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('SideBar Text Inactived Color')"
                        label-for="input-lg-sideBarTextInactivedColor"
                      >
                        <b-form-input
                          id="input-lg-sideBarTextInactivedColor"
                          v-model="item.styles.sideBarTextInactivedColor"
                          placeholder="SideBar Text Inactived Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-sideBarTextInactivedColor"
                          v-model="item.styles.sideBarTextInactivedColor"
                          placeholder="SideBar Text Inactived Color"
                        />
                      </b-form-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('SideBar Icon') }} : </b-td>
                    <b-td colspan="2">
                      <b-form-group
                        :label="$t('SideBar Icon Size')"
                        label-for="input-lg-sideBarIconSize"
                      >
                        <!-- <b-form-input
                          id="input-lg-iconTopicSize"
                          v-model="item.styles.iconTopicSize"
                          placeholder="Icon Topic Size"
                        /> -->
                        <cleave
                          id="input-lg-sideBarIconSize"
                          v-model="item.styles.sideBarIconSize"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="SideBar Icon Size"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('SideBar Icon Handle Color')"
                        label-for="input-lg-sideBarIconHandleColor"
                      >
                        <b-form-input
                          id="input-lg-sideBarIconHandleColor"
                          v-model="item.styles.sideBarIconHandleColor"
                          placeholder="SideBar Icon Handle Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-sideBarIconHandleColor"
                          v-model="item.styles.sideBarIconHandleColor"
                          placeholder="SideBar Icon Handle Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('SideBar Icon Actived Color')"
                        label-for="input-lg-sideBarIconActivedColor"
                      >
                        <b-form-input
                          id="input-lg-sideBarIconActivedColor"
                          v-model="item.styles.sideBarIconActivedColor"
                          placeholder="SideBar Icon Actived Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-sideBarIconActivedColor"
                          v-model="item.styles.sideBarIconActivedColor"
                          placeholder="SideBar Icon Actived Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('SideBar Icon Inactived Color')"
                        label-for="input-lg-sideBarIconInactivedColor"
                      >
                        <b-form-input
                          id="input-lg-sideBarIconInactivedColor"
                          v-model="item.styles.sideBarIconInactivedColor"
                          placeholder="SideBar Icon Inactived Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-sideBarIconInactivedColor"
                          v-model="item.styles.sideBarIconInactivedColor"
                          placeholder="SideBar Icon Inactived Color"
                        />
                      </b-form-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('SideBar') }} : </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('SideBar Background Color')"
                        label-for="input-lg-sideBarBackgroundColor"
                      >
                        <b-form-input
                          id="input-lg-sideBarBackgroundColor"
                          v-model="item.styles.sideBarBackgroundColor"
                          placeholder="SideBar Background Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-sideBarBackgroundColor"
                          v-model="item.styles.sideBarBackgroundColor"
                          placeholder="SideBar Background Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('SideBar Border Color')"
                        label-for="input-lg-sideBarBorderColor"
                      >
                        <b-form-input
                          id="input-lg-sideBarBorderColor"
                          v-model="item.styles.sideBarBorderColor"
                          placeholder="SideBar Border Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-sideBarBorderColor"
                          v-model="item.styles.sideBarBorderColor"
                          placeholder="SideBar Border Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('SideBar Border Radius')"
                        label-for="input-lg-sideBarBorderRadius"
                      >
                        <!-- <b-form-input
                          id="input-lg-cardBorderRadius"
                          v-model="item.styles.cardBorderRadius"
                          placeholder="Card Border Radius"
                        /> -->
                        <cleave
                          id="input-lg-sideBarBorderRadius"
                          v-model="item.styles.sideBarBorderRadius"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="SideBar Border Radius"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('SideBar Border Width')"
                        label-for="input-lg-sideBarBorderWidth"
                      >
                        <!-- <b-form-input
                          id="input-lg-cardBorderWidth"
                          v-model="item.styles.cardBorderWidth"
                          placeholder="Card Border Width"
                        /> -->
                        <cleave
                          id="input-lg-sideBarBorderWidth"
                          v-model="item.styles.sideBarBorderWidth"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="SideBar Border Width"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td />
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Text Topic') }} : </b-td>
                    <b-td colspan="2">
                      <b-form-group
                        :label="$t('Text Topic Size')"
                        label-for="input-lg-textTopicSize"
                      >
                        <!-- <b-form-input
                          id="input-lg-textTopicSize"
                          v-model="item.styles.textTopicSize"
                          placeholder="Text Topic Size"
                        /> -->
                        <cleave
                          id="input-lg-textTopicSize"
                          v-model="item.styles.textTopicSize"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="Text Topic Size"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Text Topic Handle')"
                        label-for="input-lg-textTopicHandleColor"
                      >
                        <b-form-input
                          id="input-lg-textTopicHandleColor"
                          v-model="item.styles.textTopicHandleColor"
                          placeholder="Text Topic Handle Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-textTopicHandleColor"
                          v-model="item.styles.textTopicHandleColor"
                          placeholder="Text Topic Handle Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Text Topic Actived')"
                        label-for="input-lg-textTopicActivedColor"
                      >
                        <b-form-input
                          id="input-lg-textTopicActivedColor"
                          v-model="item.styles.textTopicActivedColor"
                          placeholder="Text Topic Actived Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-textContentActivedColor"
                          v-model="item.styles.textContentActivedColor"
                          placeholder="Text Content Actived Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Text Topic Inactived')"
                        label-for="input-lg-textContentInactivedColor"
                      >
                        <b-form-input
                          id="input-lg-textContentInactivedColor"
                          v-model="item.styles.textContentInactivedColor"
                          placeholder="Text Content Inactived Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-textContentInactivedColor"
                          v-model="item.styles.textContentInactivedColor"
                          placeholder="Text Content Inactived Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td />

                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Text Content') }} : </b-td>
                    <b-td colspan="2">
                      <b-form-group
                        :label="$t('Text Content Size')"
                        label-for="input-lg-textContentSize"
                      >
                        <!-- <b-form-input
                          id="input-lg-textContentSize"
                          v-model="item.styles.textContentSize"
                          placeholder="Text Content Size"
                        /> -->
                        <cleave
                          id="input-lg-textContentSize"
                          v-model="item.styles.textContentSize"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="Text Content Size"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Text Content Handle')"
                        label-for="input-lg-textContentHandleColor"
                      >
                        <b-form-input
                          id="input-lg-textContentHandleColor"
                          v-model="item.styles.textContentHandleColor"
                          placeholder="Text Content Handle Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-textContentHandleColor"
                          v-model="item.styles.textContentHandleColor"
                          placeholder="Text Content Handle Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Text Content Actived')"
                        label-for="input-lg-textContentActivedColor"
                      >
                        <b-form-input
                          id="input-lg-textContentActivedColor"
                          v-model="item.styles.textContentActivedColor"
                          placeholder="Text Content Actived Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-textContentActivedColor"
                          v-model="item.styles.textContentActivedColor"
                          placeholder="Text Content Actived Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Text Content Inactived')"
                        label-for="input-lg-textContentInactivedColor"
                      >
                        <b-form-input
                          id="input-lg-textContentInactivedColor"
                          v-model="item.styles.textContentInactivedColor"
                          placeholder="Text Content Inactived Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-textContentInactivedColor"
                          v-model="item.styles.textContentInactivedColor"
                          placeholder="Text Content Inactived Color"
                        />
                      </b-form-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Icon Topic') }} : </b-td>
                    <b-td colspan="2">
                      <b-form-group
                        :label="$t('Icon Topic Size')"
                        label-for="input-lg-iconTopicSize"
                      >
                        <!-- <b-form-input
                          id="input-lg-iconTopicSize"
                          v-model="item.styles.iconTopicSize"
                          placeholder="Icon Topic Size"
                        /> -->
                        <cleave
                          id="input-lg-iconTopicSize"
                          v-model="item.styles.iconTopicSize"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="Icon Topic Size"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Icon Topic Handle')"
                        label-for="input-lg-iconTopicHandleColor"
                      >
                        <b-form-input
                          id="input-lg-iconTopicHandleColor"
                          v-model="item.styles.iconTopicHandleColor"
                          placeholder="Icon Topic Handle Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-iconTopicHandleColor"
                          v-model="item.styles.iconTopicHandleColor"
                          placeholder="Icon Topic Handle Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Icon Topic Actived')"
                        label-for="input-lg-iconTopicActivedColor"
                      >
                        <b-form-input
                          id="input-lg-iconTopicActivedColor"
                          v-model="item.styles.iconTopicActivedColor"
                          placeholder="Icon Topic Actived Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-iconTopicActivedColor"
                          v-model="item.styles.iconTopicActivedColor"
                          placeholder="Icon Topic Actived Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Icon Topic Inactived')"
                        label-for="input-lg-iconTopicInactivedColor"
                      >
                        <b-form-input
                          id="input-lg-iconTopicInactivedColor"
                          v-model="item.styles.iconTopicInactivedColor"
                          placeholder="Icon Topic Inactived Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-iconTopicInactivedColor"
                          v-model="item.styles.iconTopicInactivedColor"
                          placeholder="Icon Topic Inactived Color"
                        />
                      </b-form-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Icon Content') }} : </b-td>
                    <b-td colspan="2">
                      <b-form-group
                        :label="$t('Icon Content Size')"
                        label-for="input-lg-iconContentSize"
                      >
                        <!-- <b-form-input
                          id="input-lg-iconContentSize"
                          v-model="item.styles.iconContentSize"
                          placeholder="Icon Content Size"
                        /> -->
                        <cleave
                          id="input-lg-iconContentSize"
                          v-model="item.styles.iconContentSize"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="Icon Content Size"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Icon Content Handle')"
                        label-for="input-lg-iconContentHandleColor"
                      >
                        <b-form-input
                          id="input-lg-iconContentHandleColor"
                          v-model="item.styles.iconContentHandleColor"
                          placeholder="Icon Content Handle Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-iconContentHandleColor"
                          v-model="item.styles.iconContentHandleColor"
                          placeholder="Icon Content Handle Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Icon Content Actived')"
                        label-for="input-lg-iconContentActivedColor"
                      >
                        <b-form-input
                          id="input-lg-iconContentActivedColor"
                          v-model="item.styles.iconContentActivedColor"
                          placeholder="Icon Content Actived Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-iconContentActivedColor"
                          v-model="item.styles.iconContentActivedColor"
                          placeholder="Icon Content Actived Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Icon Content Inactived')"
                        label-for="input-lg-iconContentInactivedColor"
                      >
                        <b-form-input
                          id="input-lg-iconContentInactivedColor"
                          v-model="item.styles.iconContentInactivedColor"
                          placeholder="Icon Content Inactived Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-iconContentInactivedColor"
                          v-model="item.styles.iconContentInactivedColor"
                          placeholder="Icon Content Inactived Color"
                        />
                      </b-form-group>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Background') }} : </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Background Handle')"
                        label-for="input-lg-backgroundHandleColor"
                      >
                        <b-form-input
                          id="input-lg-backgroundHandleColor"
                          v-model="item.styles.backgroundHandleColor"
                          placeholder="Background Handle Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-backgroundHandleColor"
                          v-model="item.styles.backgroundHandleColor"
                          placeholder="Background Handle Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Background Actived')"
                        label-for="input-lg-backgroundActivedColor"
                      >
                        <b-form-input
                          id="input-lg-backgroundActivedColor"
                          v-model="item.styles.backgroundActivedColor"
                          placeholder="Background Actived Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-backgroundActivedColor"
                          v-model="item.styles.backgroundActivedColor"
                          placeholder="Background Actived Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Background Inactived')"
                        label-for="input-lg-backgroundInactivedColor"
                      >
                        <b-form-input
                          id="input-lg-backgroundInactivedColor"
                          v-model="item.styles.backgroundInactivedColor"
                          placeholder="Background Inactived Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-backgroundInactivedColor"
                          v-model="item.styles.backgroundInactivedColor"
                          placeholder="Background Inactived Color"
                        />
                      </b-form-group>
                    </b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>{{ $t('Button Primary') }} : </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Button Size')"
                        label-for="input-lg-buttonSize"
                      >
                        <!-- <b-form-input
                          id="input-lg-buttonSize"
                          v-model="item.styles.buttonSize"
                          placeholder="Button Size"
                        /> -->
                        <cleave
                          id="input-lg-buttonSize"
                          v-model="item.styles.buttonSize"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="Button Size"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Button Color')"
                        label-for="input-lg-buttonColor"
                      >
                        <b-form-input
                          id="input-lg-buttonColor"
                          v-model="item.styles.buttonColor"
                          placeholder="Button Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-buttonColor"
                          v-model="item.styles.buttonColor"
                          placeholder="Button Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Button Border Radius')"
                        label-for="input-lg-buttonBorderRadius"
                      >
                        <!-- <b-form-input
                          id="input-lg-buttonBorderRadius"
                          v-model="item.styles.buttonBorderRadius"
                          placeholder="Button Border Radius"
                        /> -->
                        <cleave
                          id="input-lg-buttonBorderRadius"
                          v-model="item.styles.buttonBorderRadius"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="Button Border Radius"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Text Button Size')"
                        label-for="input-lg-textButtonSize"
                      >
                        <!-- <b-form-input
                          id="input-lg-textButtonSize"
                          v-model="item.styles.textButtonSize"
                          placeholder="Text Button Size"
                        /> -->
                        <cleave
                          id="input-lg-textButtonSize"
                          v-model="item.styles.textButtonSize"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="Text Button Size"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Text Button Color')"
                        label-for="input-lg-textButtonColor"
                      >
                        <b-form-input
                          id="input-lg-textButtonColor"
                          v-model="item.styles.textButtonColor"
                          placeholder="Text Button Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-textButtonColor"
                          v-model="item.styles.textButtonColor"
                          placeholder="Text Button Color"
                        />
                      </b-form-group>
                    </b-td>

                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Button Success') }} : </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Button Size')"
                        label-for="input-lg-buttonSuccessSize"
                      >
                        <!-- <b-form-input
                          id="input-lg-buttonSize"
                          v-model="item.styles.buttonSize"
                          placeholder="Button Size"
                        /> -->
                        <cleave
                          id="input-lg-buttonSuccessSize"
                          v-model="item.styles.buttonSuccessSize"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="Button Size"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Button Color')"
                        label-for="input-lg-buttonSuccessColor"
                      >
                        <b-form-input
                          id="input-lg-buttonSuccessColor"
                          v-model="item.styles.buttonSuccessColor"
                          placeholder="Button Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-buttonSuccessColor"
                          v-model="item.styles.buttonSuccessColor"
                          placeholder="Button Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Button Border Radius')"
                        label-for="input-lg-buttonSuccessBorderRadius"
                      >
                        <!-- <b-form-input
                          id="input-lg-buttonBorderRadius"
                          v-model="item.styles.buttonBorderRadius"
                          placeholder="Button Border Radius"
                        /> -->
                        <cleave
                          id="input-lg-buttonSuccessBorderRadius"
                          v-model="item.styles.buttonSuccessBorderRadius"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="Button Border Radius"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Text Button Size')"
                        label-for="input-lg-textButtonSuccessSize"
                      >
                        <!-- <b-form-input
                          id="input-lg-textButtonSize"
                          v-model="item.styles.textButtonSize"
                          placeholder="Text Button Size"
                        /> -->
                        <cleave
                          id="input-lg-textButtonSuccessSize"
                          v-model="item.styles.textButtonSuccessSize"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="Text Button Size"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Text Button Color')"
                        label-for="input-lg-textButtonSuccessColor"
                      >
                        <b-form-input
                          id="input-lg-textButtonSuccessColor"
                          v-model="item.styles.textButtonSuccessColor"
                          placeholder="Text Button Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-textButtonSuccessColor"
                          v-model="item.styles.textButtonSuccessColor"
                          placeholder="Text Button Color"
                        />
                      </b-form-group>
                    </b-td>

                  </b-tr>
                  <b-tr>
                    <b-td>{{ $t('Button Cancel') }} : </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Button Size')"
                        label-for="input-lg-buttonCancelSize"
                      >
                        <!-- <b-form-input
                          id="input-lg-buttonSize"
                          v-model="item.styles.buttonSize"
                          placeholder="Button Size"
                        /> -->
                        <cleave
                          id="input-lg-buttonCancelSize"
                          v-model="item.styles.buttonCancelSize"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="Button Size"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Button Color')"
                        label-for="input-lg-buttonCancelColor"
                      >
                        <b-form-input
                          id="input-lg-buttonCancelColor"
                          v-model="item.styles.buttonCancelColor"
                          placeholder="Button Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-buttonCancelColor"
                          v-model="item.styles.buttonCancelColor"
                          placeholder="Button Color"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Button Border Radius')"
                        label-for="input-lg-buttonCancelBorderRadius"
                      >
                        <!-- <b-form-input
                          id="input-lg-buttonBorderRadius"
                          v-model="item.styles.buttonBorderRadius"
                          placeholder="Button Border Radius"
                        /> -->
                        <cleave
                          id="input-lg-buttonCancelBorderRadius"
                          v-model="item.styles.buttonCancelBorderRadius"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="Button Border Radius"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Text Button Size')"
                        label-for="input-lg-textButtonCancelSize"
                      >
                        <!-- <b-form-input
                          id="input-lg-textButtonSize"
                          v-model="item.styles.textButtonSize"
                          placeholder="Text Button Size"
                        /> -->
                        <cleave
                          id="input-lg-textButtonCancelSize"
                          v-model="item.styles.textButtonSuccessSize"
                          class="form-control"
                          :raw="false"
                          :options="optionCleave.number"
                          placeholder="Text Button Size"
                        />
                      </b-form-group>
                    </b-td>
                    <b-td>
                      <b-form-group
                        :label="$t('Text Button Color')"
                        label-for="input-lg-textButtonCancelColor"
                      >
                        <b-form-input
                          id="input-lg-textButtonCancelColor"
                          v-model="item.styles.textButtonCancelColor"
                          placeholder="Text Button Color"
                          type="color"
                        />
                        <b-form-input
                          id="input-lg-textButtonCancelColor"
                          v-model="item.styles.textButtonCancelColor"
                          placeholder="Text Button Color"
                        />
                      </b-form-group>
                    </b-td>

                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-tab>
          </b-tabs>
          <b-button
            size="sm"
            variant="primary"
            @click="update()"
          >
            <span class="mr-25 align-middle"> {{ $t('Save') }}</span>
            <feather-icon
              icon="SaveIcon"
              size="12"
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BDropdownItem,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTd,
  BTabs,
  BTab,
  BFormCheckbox,
  BFormFile,
  BMediaBody,
  BLink,
  BMediaAside,
  BMedia,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/styleApplication'
import debounce from 'debounce'
import Cleave from 'vue-cleave-component'

const STORE_MODULE_NAME = 'styleApplicaion'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTabs,
    BTab,
    BFormCheckbox,
    BFormFile,
    BMediaBody,
    BLink,
    BMediaAside,
    BMedia,
    BFormGroup,
    Cleave,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      show: false,
      indexSelected: 0,
      bottomNavigations: [],
      pages: [

      ],
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      perPageOptions,
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.max
        : 0
    },
    OptionRenderStyles() {
      if (this.pages[this.indexSelected].tagMenu === 'LOGIN') {
        return [
          { name: `${this.$t('Style')} 1`, value: 0, image: require('@/assets/images/example/preset/homePage/preset1.png') },
          { name: `${this.$t('Style')} 2`, value: 1, image: require('@/assets/images/example/preset/homePage/preset2.png') },
        ]
      } if (this.pages[this.indexSelected].tagMenu === 'REGISTER') {
        return [
          { name: `${this.$t('Style')} 1`, value: 0, image: require('@/assets/images/example/preset/homePage/preset1.png') },
          { name: `${this.$t('Style')} 2`, value: 1, image: require('@/assets/images/example/preset/homePage/preset2.png') },
        ]
      }
      return []
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    this.init()
    this.get()
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    addFileInput(index) {
      // Add a new null element to the fileInputs array
      this.$refs[`fileInput_${index}`][0].$el.click()
    },
    resetImage(index) {
      this.pages[index].backgroundImage = null
    },
    inputImageRenderer(input, index) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        // if (input.target.files[0].size > 300000) {
        //   this.$toast({
        //     component: ToastificationContent,
        //     position: 'bottom-right',
        //     props: {
        //       title: this.$t('Error'),
        //       icon: 'ErrorIcon',
        //       variant: 'danger',
        //       text: `${this.$t('Allowed')} JPG, GIF ${this.$t(
        //         'or',
        //       )} PNG. ${this.$t('Max size of')} 300KB`,
        //     },
        //   })
        //   return
        // }

        const reader = new FileReader()
        reader.onload = e => {
          this.pages[index].backgroundImage = e.target.result

          // this.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    selectPage(index) {
      this.indexSelected = index
    },
    get() {
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, {})
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          console.log(result.data.data)
          const { pages } = result.data.data
          this.pageData(pages)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    pageData(pages) {
      console.log(pages)
      for (let i = 0; i < pages.length; i += 1) {
        const index = this.pages.findIndex(e => e.tagMenu === pages[i].tagMenu)
        if (index > -1) {
          // this.pages[index] = pages[i]
          Object.assign(this.pages[index], pages[i])
        }
      }
    },
    processStyles(styles) {
      // eslint-disable-next-line no-restricted-syntax
      const format = {}
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in styles) {
        if (typeof styles[key] === 'string') {
          format[key] = styles[key].replaceAll(',', '')
        } else {
          format[key] = styles[key]
        }
      }

      return format
    },
    update() {
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/update`, {
          bottomNavigations: this.bottomNavigations,
          pages: this.pages.map(e => ({
            ...e,
            styles: this.processStyles(e.styles),
          })),
        })
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    init() {
      const pageName = ['HOME_PAGE', 'PRODUCT', 'PRODUCT_CATEGORY', 'API', 'ME', 'LOGIN', 'REGISTER']

      this.pages = pageName.map(e => ({
        renderStyle: 0,
        tagMenu: e, // กรณีที่มีเมนูไม่ได้ถูกสร้างขึ้นมาใหม่จะมี tag
        backgroundImage: null,
        used: false, // ใช้การตั้งค่าสี
        styles: {
          // Card Content
          cardBackgroundColor: '#FFFFFF', // สีฟอนต์ไม่มีเหตุการณ์
          cardBorderColor: '#FFFFFF', // สีฟอนต์ไม่มีเหตุการณ์
          cardBorderRadius: 0, // ขอบมน
          cardBorderWidth: 0.5, // ขนาดเส้นขอบ

          paddingContent: 0, // ระยะห่างระหว่างเนื่อหา

          // Sidebar
          sideBarTextSize: 0, // ขนาดฟอนต์
          sideBarTextHandleColor: '#FFFFFF', // สีฟอนต์ไม่มีเหตุการณ์
          sideBarTextActivedColor: '#FFFFFF', // สีฟอนต์ไม่มีเหตุการณ์
          sideBarTextInactivedColor: '#FFFFFF', // สีฟอนต์ไม่มีเหตุการณ์
          sideBarIconSize: 0, // ขนาดฟอนต์
          sideBarIconHandleColor: '#FFFFFF', // สีฟอนต์ไม่มีเหตุการณ์
          sideBarIconActivedColor: '#FFFFFF', // สีฟอนต์ไม่มีเหตุการณ์
          sideBarIconInactivedColor: '#FFFFFF', // สีฟอนต์ไม่มีเหตุการณ์
          sideBarBackgroundColor: '#FFFFFF', // สีฟอนต์ไม่มีเหตุการณ์
          sideBarBorderColor: '#FFFFFF', // สีฟอนต์ไม่มีเหตุการณ์
          sideBarBorderRadius: 0, // ขอบมน
          sideBarBorderWidth: 0.5, // ขนาดเส้นขอบ

          // Text Topic
          textTopicSize: 0, // ขนาดฟอนต์
          textTopicHandleColor: '#FFFFFF', // สีฟอนต์ไม่มีเหตุการณ์
          textTopicActivedColor: '#FFFFFF', // สีฟอนต์ตอนกดอยู่
          textTopicInactivedColor: '#FFFFFF', // สีฟอนต์ตอนไม่ได้กด

          // Text Content
          textContentSize: 0, // ขนาดฟอนต์
          textContentHandleColor: '#FFFFFF', // สีฟอนต์ไม่มีเหตุการณ์
          textContentActivedColor: '#FFFFFF', // สีฟอนต์ตอนกดอยู่
          textContentInactivedColor: '#FFFFFF', // สีฟอนต์ตอนไม่ได้กด

          // Icon Topic
          iconTopicSize: 0, // ขนาดฟอนต์ (สำหรับไอคอน)
          iconTopicHandleColor: '#FFFFFF', // ไอคอนไม่มีเหตุการณ์ (สำหรับไอคอน)
          iconTopicActivedColor: '#FFFFFF', // ไอคอนตอนกดอยู่ (สำหรับไอคอน)
          iconTopicInactivedColor: '#FFFFFF', // ไอคอนตอนไม่ได้กด (สำหรับไอคอน)

          // Icon Content
          iconContentSize: 0, // ขนาดฟอนต์ (สำหรับไอคอน)
          iconContentHandleColor: '#FFFFFF', // ไอคอนไม่มีเหตุการณ์ (สำหรับไอคอน)
          iconContentActivedColor: '#FFFFFF', // ไอคอนตอนกดอยู่ (สำหรับไอคอน)
          iconContentInactivedColor: '#FFFFFF', // ไอคอนตอนไม่ได้กด (สำหรับไอคอน)

          // Background
          backgroundHandleColor: '#FFFFFF', // สีพื้นหลังไม่มีเหตุการณ์
          backgroundActivedColor: '#FFFFFF', // สีพื้นหลังตอนกดอยู่
          backgroundInactivedColor: '#FFFFFF', // สีพื้นหลังตอนไม่ได้กด

          // Title
          backgroundTitleCurrentColor: '#FFFFFF', // สีพื้นหลังหัวข้อ Route ที่เป็น Current
          backgroundTitleNewColor: '#FFFFFF', // สีพื้นหลังหัวข้อ Route ที่เป็น New
          iconTitleCurrentColor: '#FFFFFF', // ไอคอนตอนบนหัวข้อ Route ที่เป็น Current
          iconTitleNewColor: '#FFFFFF', // ไอคอนตอนบนหัวข้อ Route ที่เป็น New
          iconTitleActionCurrentColor: '#FFFFFF', // ไอคอนตอนบนหัวข้อ Route ที่เป็น Current
          iconTitleActionNewColor: '#FFFFFF', // ไอคอนตอนบนหัวข้อ Route ที่เป็น New
          textTitleCurrentColor: '#FFFFFF', // สีฟอนต์บนหัวข้อ Route ที่เป็น Current
          textTitleNewColor: '#FFFFFF', // สีฟอนต์บนหัวข้อ Route ที่เป็น New

          // Button Primary
          buttonSize: 0, // ขนาดฟอนต์ (สำหรับไอคอน)
          buttonColor: '#FFFFFF', // สีพื้นหลังหัวข้อ Route ที่เป็น Current
          buttonBorderRadius: 0, // ขอบมน
          textButtonSize: 0, // ขนาดฟอนต์ (สำหรับไอคอน)
          textButtonColor: '#FFFFFF', // ไอคอนตอนบนหัวข้อ Route ที่เป็น Current

          // Button Success
          buttonSuccessSize: 0, // ขนาดฟอนต์ (สำหรับไอคอน)
          buttonSuccessColor: '#FFFFFF', // สีพื้นหลังหัวข้อ Route ที่เป็น Current
          buttonSuccessBorderRadius: 0, // ขอบมน
          textButtonSuccessSize: 0, // ขนาดฟอนต์ (สำหรับไอคอน)
          textButtonSuccessColor: '#FFFFFF', // ไอคอนตอนบนหัวข้อ Route ที่เป็น Current

          // Button Cancel
          buttonCancelSize: 0, // ขนาดฟอนต์ (สำหรับไอคอน)
          buttonCancelColor: '#FFFFFF', // สีพื้นหลังหัวข้อ Route ที่เป็น Current
          buttonCancelBorderRadius: 0, // ขอบมน
          textButtonCancelSize: 0, // ขนาดฟอนต์ (สำหรับไอคอน)
          textButtonCancelColor: '#FFFFFF', // ไอคอนตอนบนหัวข้อ Route ที่เป็น Current

        },
      }))
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
